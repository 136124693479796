import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./LeftSideMenu.module.css";

interface Props {
  // Add any props you might want to pass later
}

// Custom Hamburger Icon Component
const IconMenu = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-menu"
    width="24"
    height="24"
  >
    <line x1="3" y1="12" x2="21" y2="12"></line>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <line x1="3" y1="18" x2="21" y2="18"></line>
  </svg>
);

// Custom Close Icon Component
const IconClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x"
    width="24"
    height="24"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

export const LeftSideMenu: FC<Props> = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <>
      {/* Mobile Toggle Button */}
      <button className={styles.menuButton} onClick={toggleMenu}>
        {menuOpen ? <IconClose /> : <IconMenu />}
      </button>

      {/* Menu Overlay */}
      <nav className={`${styles.menu} ${menuOpen ? styles.open : ""}`}>
        <ul>
          <li>
            <Link to="/football" onClick={toggleMenu}>{t("Football")}</Link>
          </li>
          <li>
            <Link to="/404" onClick={toggleMenu}>{t("Gaming")}</Link>
          </li>
          <li>
            <Link to="/404" onClick={toggleMenu}>{t("Fighting")}</Link>
          </li>
          <li>
            <Link to="/404" onClick={toggleMenu}>{t("Motorsport")}</Link>
          </li>
          <li>
            <Link to="/404" onClick={toggleMenu}>{t("Tennis")}</Link>
          </li>
          <li>
            <Link to="/404" onClick={toggleMenu}>{t("Rugby")}</Link>
          </li>
          <li>
            <Link to="/404" onClick={toggleMenu}>{t("Cricket")}</Link>
          </li>
          <li>
            <Link to="/404" onClick={toggleMenu}>{t("Olympics")}</Link>
          </li>
          <li>
            <Link to="/404" onClick={toggleMenu}>{t("Battle Bots")}</Link>
          </li>
          {/* Add more menu items as necessary */}
        </ul>
      </nav>

      {/* Dim Background when Menu is Open */}
      {menuOpen && <div className={styles.overlay} onClick={toggleMenu}></div>}
    </>
  );
};
