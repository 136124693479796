/*
Copyright 2022-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only
Please see LICENSE in the repository root for full details.
*/

import { useState, useCallback, FormEvent, FormEventHandler, FC } from "react";
import { useHistory } from "react-router-dom";
import { MatrixClient } from "matrix-js-sdk/src/client";
import { useTranslation } from "react-i18next";
import { Heading, Text } from "@vector-im/compound-web";
import { logger } from "matrix-js-sdk/src/logger";
import { Button } from "@vector-im/compound-web";
import "./DisclaimerBox.module.css"; // Import the new CSS file

import {
  createRoom,
  getRelativeRoomUrl,
  roomAliasLocalpartFromRoomName,
  sanitiseRoomNameInput,
} from "../utils/matrix";
import { useGroupCallRooms } from "./useGroupCallRooms";
import { Header, HeaderLogo, LeftNav, RightNav } from "../Header";
import commonStyles from "./common.module.css";
import styles from "./RegisteredView.module.css";
import { FieldRow, InputField, ErrorMessage } from "../input/Input";
import { CallList } from "./CallList";
import { UserMenuContainer } from "../UserMenuContainer";
import { JoinExistingCallModal } from "./JoinExistingCallModal";
import { Form } from "../form/Form";
import { AnalyticsNotice } from "../analytics/AnalyticsNotice";
import { E2eeType } from "../e2ee/e2eeType";
import { useOptInAnalytics } from "../settings/settings";
import { LeftSideMenu } from "./LeftSideMenu"; // Import the newly created menu
export const RegisteredView: FC<Props> = ({ client }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={commonStyles.container}>
        <Header>
          <LeftNav>
            <HeaderLogo />
            <LeftSideMenu /> {/* Add the LeftSideMenu here */}
          </LeftNav>
          <RightNav>
            <UserMenuContainer />
          </RightNav>
        </Header>

        <main className={commonStyles.main}>
          <HeaderLogo className={commonStyles.logo} />
          <Heading size="lg" weight="semibold">
            {t("Fanwatch")}
          </Heading>

          {/* Disclaimer Box */}
          <div className={commonStyles.disclaimerBox}>
    <h2 className={commonStyles.disclaimerHeading}>Disclaimer</h2>
    <ul className={commonStyles.disclaimerList}>
        <li className={commonStyles.disclaimerListItem}>
            {t("FanWatch compensates users for watching matches. By participating, you acknowledge and consent that FanWatch retains complete access to, and usage rights over, any activities within the video stream, including but not limited to, recording and sharing content with third-party vendors.")}
        </li>
        <li className={commonStyles.disclaimerListItem}>
            {t("Users are required to adhere to instructions from moderators and focus on the ongoing match. Failure to comply with these guidelines may result in actions taken by FanWatch, including removal from the event.")}
        </li>
        <li className={commonStyles.disclaimerListItem}>
            {t("FanWatch reserves the right to take appropriate actions against users who violate platform rules or fail to comply with moderator instructions, including but not limited to, removing users from rooms, claiming staked fees, or implementing other necessary measures to maintain the integrity of the platform.")}
        </li>
        <li className={commonStyles.disclaimerListItem}>
            {t("Users are compensated on a per-minute basis for their time spent watching matches. To receive these earnings, users must manually claim their fees after leaving the match. FanWatch is not liable for unclaimed or delayed payments due to user inactivity.")}
        </li>
        <li className={commonStyles.disclaimerListItem}>
            {t("FanWatch is not responsible for any financial losses, technical issues, or disruptions that users may experience while using the platform. Users acknowledge that they are participating at their own risk and that digital assets may be subject to unforeseen risks.")}
        </li>
        <li className={commonStyles.disclaimerListItem}>
            {t("FanWatch is currently in its beta phase and is under active development. Features, functionality, and terms of service may change without prior notice as we continue to enhance and improve the platform.")}
        </li>
    </ul>
</div>



        </main>
      </div>

    </>
  );
};
