import { FC, useState, useCallback,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Heading, Text } from "@vector-im/compound-web";
import { Button } from "@vector-im/compound-web";
import { UserMenuContainer } from "../UserMenuContainer";
import { Form } from "../form/Form";

import { Header, HeaderLogo, LeftNav, RightNav } from "../Header";
import { LeftSideMenu } from "./LeftSideMenu";
import commonStyles from "./common.module.css";
import styles from "./FootballView.module.css"; // Specific styles for FootballView
import { ethers } from "ethers";
import { BrowserProvider } from "ethers";
import { useClientState } from "../ClientContext";

interface Props {
  client: any;
}

function gweiToEther(gwei) {
    return Number(gwei) / 1e9; // 1 Ether = 1e9 Gwei
}
const ABI =[
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "creator",
				"type": "address"
			}
		],
		"name": "RoomCreated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			}
		],
		"name": "RoomDeactivated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "enum RoomStaking.RoomState",
				"name": "newState",
				"type": "uint8"
			}
		],
		"name": "RoomStateChanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ticketId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "TicketIssued",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "string",
				"name": "offchain",
				"type": "string"
			}
		],
		"name": "UserRemovedAndPunished",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "stakedAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "rewardAmount",
				"type": "uint256"
			}
		],
		"name": "UserRemovedAndRewarded",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			},
			{
				"internalType": "enum RoomStaking.RoomState",
				"name": "newState",
				"type": "uint8"
			}
		],
		"name": "changeRoomState",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "description",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "link",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "stake_cost",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "reward_pool",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "reward_per_minute",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "max_tickets",
				"type": "uint256"
			},
			{
				"internalType": "address[]",
				"name": "moderators",
				"type": "address[]"
			}
		],
		"name": "createRoom",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			}
		],
		"name": "deactivateRoom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "offchainId",
				"type": "string"
			}
		],
		"name": "joinRoom",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "offchainId",
				"type": "string"
			}
		],
		"name": "removeAndPunish",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "removeAndReward",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			}
		],
		"name": "removeRoom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "transferAllFundsToOwner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "getAllRooms",
		"outputs": [
			{
				"components": [
					{
						"internalType": "string",
						"name": "id",
						"type": "string"
					},
					{
						"internalType": "enum RoomStaking.RoomState",
						"name": "state",
						"type": "uint8"
					},
					{
						"internalType": "string",
						"name": "name",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "description",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "link",
						"type": "string"
					},
					{
						"internalType": "address",
						"name": "creator",
						"type": "address"
					},
					{
						"internalType": "address[]",
						"name": "moderators",
						"type": "address[]"
					},
					{
						"internalType": "uint256",
						"name": "stake_cost",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "reward_pool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "reward_per_minute",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "max_tickets",
						"type": "uint256"
					},
					{
						"internalType": "uint256[]",
						"name": "ticket_ids",
						"type": "uint256[]"
					}
				],
				"internalType": "struct RoomStaking.Room[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			}
		],
		"name": "getUsersInRoom",
		"outputs": [
			{
				"internalType": "string[]",
				"name": "",
				"type": "string[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "listAllTickets",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "id",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "user_address",
						"type": "address"
					},
					{
						"internalType": "string",
						"name": "offchain_address",
						"type": "string"
					},
					{
						"internalType": "uint256",
						"name": "creation_time",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "stake_amount",
						"type": "uint256"
					},
					{
						"internalType": "string",
						"name": "roomId",
						"type": "string"
					}
				],
				"internalType": "struct RoomStaking.Ticket[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "listRooms",
		"outputs": [
			{
				"internalType": "string[]",
				"name": "",
				"type": "string[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"name": "offchainTickets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "roomIds",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"name": "rooms",
		"outputs": [
			{
				"internalType": "string",
				"name": "id",
				"type": "string"
			},
			{
				"internalType": "enum RoomStaking.RoomState",
				"name": "state",
				"type": "uint8"
			},
			{
				"internalType": "string",
				"name": "name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "description",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "link",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "creator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "stake_cost",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "reward_pool",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "reward_per_minute",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "max_tickets",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tickets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "user_address",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "offchain_address",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "creation_time",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "stake_amount",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "roomId",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "userTickets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]

const CONTRACT_ADDRESS = "0xa23b7776aF3f67Aac405b88503EC51C05F980857";

function proxyToDict(proxy) {
  // Check if the target is an array
  if (Array.isArray(proxy)) {
    return proxy.map(item => proxyToDict(item)); // Recursively handle arrays
  } else if (typeof proxy === 'object' && proxy !== null) {
    const target = proxy['<target>'];  // Extract the target array or object

    if (target && Array.isArray(target)) {
      // Assuming the nested arrays contain the expected fields in a specific order
      return target.map(item => {
        return {
          id: item[0],                   // string id
          state: item[1],                // RoomState state
          name: item[2],                 // string name
          description: item[3],          // string description
          link: item[4],                 // string link
          creator: item[5],              // address creator
          moderators: item[6],           // address[] moderators
          stake_cost: item[7],           // uint256 stake_cost
          reward_pool: item[8],          // uint256 reward_pool
          reward_per_minute: item[9],    // uint256 reward_per_minute
          max_tickets: item[10],         // uint256 max_tickets
          ticket_ids: item[11]           // uint256[] ticket_ids
        };
      });
    }

    // Handle regular objects
    let result = {};
    for (let key in proxy) {
      result[key] = proxyToDict(proxy[key]);
    }
    return result;
  }

  // If it's a primitive value, return as is
  return proxy;
}
function mapDataToRooms(data) {
    return data.map(internalArray => {
        return {
            id: internalArray[0], // string id
            state: Number(internalArray[1]), // RoomState (e.g., could be a string or a defined constant)
            name: internalArray[2], // string name
            description: internalArray[3], // string description
            link: internalArray[4], // string link
            creator: internalArray[5], // address creator
            moderators: internalArray[6], // address[] moderators
            stake_cost: Number(internalArray[7]), // uint256 stake_cost
            reward_pool: gweiToEther(BigInt(internalArray[8])) , // uint256 reward_pool
            reward_per_minute: gweiToEther(BigInt(internalArray[9])), // uint256 reward_per_minute
            max_tickets: Number(internalArray[10]), // uint256 max_tickets
            ticket_ids: internalArray[11] // uint256[] ticket_ids
        };
    });
}





// Dummy football matches data with additional information
const dummyMatches = [
  {
    id: 1,
    roomId: "abc123",
    link: "/match/abc123",
    stake_cost: 50,
    reward_pool: 1000,
    reward_per_minute: 2,
    max_tickets: 500,
    moderators: [{ name: "John Doe", description: "Match expert" }],
    homeTeam: "Manchester United",
    awayTeam: "Liverpool",
    date: "2024-10-10",
  },
  // ... (other matches)
];

export const FootballView: FC<Props> = ({ client }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [roomData, setRoomData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log("client",client)
  const clientState = useClientState();
console.log("client",clientState)

  useEffect(() => {

  const fetchRoomData = async () => {

    try {
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(CONTRACT_ADDRESS, ABI, signer);
      console.log(contract,"contract")
      const rooms = await contract.getAllRooms();
      if( rooms == undefined){
          return
        }
        let dict = proxyToDict(rooms);
        const main_rooms = mapDataToRooms(dict);
      console.log(main_rooms,"contract");
      setRoomData(main_rooms)


    } catch (error) {
      console.log(error,"contract")
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  fetchRoomData();
}, []); // Empty array to run only once on component mount

const handleClick = async(match,clientState)=>{
  console.log(match)
  const userAddress = clientState.authenticated.client.getUserId();

   const provider = new BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(CONTRACT_ADDRESS, ABI, signer);
   console.log(contract,userAddress)
   try{
     const walletAddr= await signer.getAddress()
     let tickets = await contract.listAllTickets();
     console.log(walletAddr,tickets,"tikcets are")
     for (let i = 0; i < tickets.length; i++) {
    // Access the nested Proxy to get the target array containing the ticket data
    const ticket = tickets[i];
    const target_copy = Object.assign({}, ticket);
    const ticketData = ticket.target; // Drilling down to access the array with ticket data
    console.log(target_copy)
    if(target_copy[1] == walletAddr){
      console.log("true")
      window.location.href = match.link;

    }
    // Check if the second element in the array matches the wallet address

}
   }catch(error){
     console.log(error)
   }

   let stringNumber = match.stake_cost.toFixed(1); // "1.0"
   const value = ethers.parseEther(stringNumber); // 1 Ether

   try{
   const tx = contract.joinRoom(match.id,userAddress, { value });
   await tx;

   console.log("Transaction successful:", match.link);
   window.location.href = match.link;


   }catch{
     console.log("error",error)

   }
}
// const handleClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
//  e.preventDefault();
//  const userAddress = client.getUserId();
//  console.log(e)
// //
// //  // Initialize ethers.js
// //  const provider = new BrowserProvider(window.ethereum);
// //  const signer = await provider.getSigner();
// //  const contract = new ethers.Contract(CONTRACT_ADDRESS, ABI, signer);
// //  console.log(contract)
// //  try {
// //
// //
// //      // If user doesn't exist, call depositAndAddDetails function
// //      const details = "Your details here"; // Replace with the details you want to add
// // const value = ethers.parseEther("1.0"); // 1 Ether
// //
// // const tx = contract.depositAndAddDetails(userAddress, { value });
// // await tx;
// //
// // console.log("Transaction successful:", tx);
// // window.location.href = url;
//
//
//    // Redirect to the link
//  } catch (error) {
//    //window.location.href = url;
//
//    console.error("Error interacting with the contract:", error);
//
//  }
// };



  return (
    <>
      <div className={commonStyles.container}>
        <Header>
          <LeftNav>
            <HeaderLogo />
            <LeftSideMenu />
          </LeftNav>
          <RightNav>
            <UserMenuContainer />
          </RightNav>
        </Header>

        <main className={commonStyles.main}>
          <HeaderLogo className={commonStyles.logo} />
          <Heading size="lg" weight="semibold">
            {t("Football")}
          </Heading>
{
  roomData?(  <ul className={styles.matchList}>

{roomData.map((match) => (
<li key={match.id} className={styles.matchItem}>
<Text size="lg" className={styles.matchText}>
  {match.name}
  <span className={`${styles.activeStatus} ${match.state === 1 ? styles.active : styles.inactive}`}></span>
</Text>
<div className={styles.detailsContainer}>
  <div className={styles.detailItem}>
    <strong>Stake Cost:</strong> {match.stake_cost}
  </div>
  <div className={styles.detailItem}>
    <strong>Reward Pool:</strong> {match.reward_pool/1000000000}
  </div>
  <div className={styles.detailItem}>
    <strong>Reward per Minute:</strong> {match.reward_per_minute/1000000000}
  </div>
  <div className={styles.detailItem}>
    <strong>Max Tickets:</strong> {match.max_tickets}
  </div>
</div>
<Text size="sm" className={styles.moderatorsText}>
  Moderators:{" "}
  {match.moderators.map((mod) => (
    <span key={mod.name}>
      {mod.name} - {mod.description}
    </span>
  ))}
</Text>
<Button
  className={styles.joinButton}
  size="sm"
  onClick={() => handleClick(match,clientState)}
>
  {t("Join")}
</Button>
</li>
))}
</ul>): (
          <div className={styles.loadingScreen}>
            <p>Loading matches...</p>
          </div>
        )
}



        </main>
      </div>
    </>
  );
};
