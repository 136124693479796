import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { ethers } from "ethers";

import { useClientState } from "../ClientContext";
import { ErrorView, LoadingView } from "../FullScreenView";
import { UnauthenticatedView } from "./UnauthenticatedView";
import { RegisteredView } from "./RegisteredView";
import { usePageTitle } from "../usePageTitle";
import { BrowserProvider } from "ethers";

export const HomePage: FC = () => {
  const { t } = useTranslation();
  usePageTitle(t("common.home"));

  const clientState = useClientState();
  const [isWalletConnected, setIsWalletConnected] = useState<boolean | null>(null);

  useEffect(() => {
    const checkWalletConnection = async () => {
      console.log(isWalletConnected)
      if (window.ethereum) {
        try {
          const provider = new BrowserProvider(window.ethereum);

          const accounts = await provider.listAccounts();
          setIsWalletConnected(accounts.length > 0);
        } catch (error) {
          console.error("Error checking wallet connection:", error);
          setIsWalletConnected(false);
        }
      } else {
        setIsWalletConnected(false);
      }
      console.log(isWalletConnected)

    };

    checkWalletConnection();
  }, []);

  if (isWalletConnected === null || !clientState) {
    return <LoadingView />;
  } else if (clientState.state === "error") {
    return <ErrorView error={clientState.error} />;
  } else {
    return isWalletConnected && clientState.authenticated ? (
      <RegisteredView client={clientState.authenticated.client} />
    ) : (
      <UnauthenticatedView />
    );
  }
};
